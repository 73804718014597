import { useContext } from "react";
import { ArtistsContext } from "../../contexts/ArtistsContext";
import "./ArtistTitle.css";

export default function ArtistTitle() {
  const { currentArtist } = useContext(ArtistsContext);

  return (
    <div className="title-gallery-container">
      <h3 className="artist-title in-gallery">{currentArtist.name}</h3>
    </div>
  );
}
