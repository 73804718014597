import "./CardInfo.css";

import Tags from "./Tags";
import Title from "./Title";
import SubTitle from "./SubTitle";
import Categories from "./Categories";
import Size from "./Size";
import Price from "./Price";

export default function CardInfo() {
  return (
    <div className="painting-info">
      <Tags />
      <Title />
      <SubTitle />
      <Categories />
      <Size />
      <Price />
    </div>
  );
}
