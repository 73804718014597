import { useContext } from "react";
import { GalleryContext } from "../../contexts/GalleryContext";
import { WidthContext } from "../../contexts/WidthContext";
import GalleryCol from "./col/GalleryCol";

import "./Gallery.css";

export default function GalleryDesktop() {
  const { windowWidth } = useContext(WidthContext);
  const { currentPaintings, visibleQty } = useContext(GalleryContext);

  const maxPaintingBoxWidth = 280;
  const paintingColumns = Math.ceil(windowWidth / maxPaintingBoxWidth);
  const paintingStyle = {
    gridTemplate: `1fr / repeat(${paintingColumns}, 1fr)`,
  };

  const newPaintingsArr = [];
  for (let i = 0; i < paintingColumns; i++) {
    newPaintingsArr.push([]);
  }

  currentPaintings.forEach((painting, i) => {
    if (i < visibleQty) newPaintingsArr[i % paintingColumns].push(painting);
  });

  return (
    <section className="gallery-desktop" style={paintingStyle}>
      {newPaintingsArr.map((paintings, i) => (
        <GalleryCol key={`col-${i}`} visiblePaintings={paintings} index={i} />
      ))}
    </section>
  );
}
