import React, { useState, useEffect, useContext } from "react";
import { GalleryContext } from "../../../../contexts/GalleryContext";
import { ArtistsContext } from "../../../../contexts/ArtistsContext";
import { motion, AnimatePresence } from "framer-motion";

import Spinner from "../../../../spinner/Spinner";
import CardText from "../card-text/CardText";
import CardArrow from "../card-arrow/CardArrow";

export default function CardImg({ painting }) {
  const { currentPainting } = useContext(GalleryContext);
  const { artists } = useContext(ArtistsContext);
  const [loaded, setLoaded] = useState(false);

  const currentArtist = artists.find(
    (artist) => artist.id === painting.artistId
  );

  useEffect(() => {
    const image = new Image();
    image.src = painting.imgSrc;
    image.onload = () => {
      setLoaded(true);
    };
  }, [painting]);

  const durationTime = 0.25;
  const variants = {
    enter: { opacity: 1 },
    center: { opacity: 1 },
    exit: { opacity: 0 },
  };

  return (
    <div className="painting-container">
      <AnimatePresence
        mode="wait"
        key="painting-preloader-anim"
        initial={false}>
        {!loaded && (
          <motion.div
            className="painting-preloader"
            key={`painting-preloader-${!loaded ? "visible" : "hidden"}`}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{ duration: durationTime }}>
            <Spinner />
          </motion.div>
        )}
      </AnimatePresence>
      <div
        className="carousel-card-bg"
        style={{
          background: `url(${painting.imgSrc}) center center / cover no-repeat`,
        }}></div>
      <div className="carousel-card-gradient"></div>
      <CardText painting={painting} artist={currentArtist} />
      <CardArrow
        clickDisable={painting.id === currentPainting.id}
        artist={currentArtist}
      />
    </div>
  );
}
