import "./Offline.css";

import { useContext } from "react";
import { PagesContext } from "../contexts/PagesContext";
import { motion, AnimatePresence } from "framer-motion";

export default function Offline() {
  const { isOffline } = useContext(PagesContext);

  const durationTime = 0.25;

  const variants = {
    enter: { opacity: 0 },
    center: { opacity: 1 },
    exit: { opacity: 0 },
  };

  const text =
    "Відсутнє підключення до інтернету. Відновіть з'єднання та спробуйте знову.";

  return (
    <AnimatePresence mode="wait" key="popup-anim" initial={false}>
      {isOffline && (
        <motion.div
          style={{ zIndex: 20 }}
          className="popup-anim-box"
          key={`popup-${isOffline ? "visible" : "hidden"}`}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{ duration: durationTime }}>
          <div className="popup-full-screen offline"></div>
          <div className="popup-body">
            <div className="popup-card">
              <h2 className="popup-text">{text}</h2>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
