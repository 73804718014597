import "./CardInfo.css";
import { useContext } from "react";
import { GalleryContext } from "../../../../contexts/GalleryContext";

export default function Tags() {
  const { currentPainting } = useContext(GalleryContext);
  const isVisible = currentPainting.tags.length !== 0;

  return isVisible ? (
    <ul className="painting-tags">
      {currentPainting.tags.map((tag, i) => (
        <li className="painting-tag" key={`tag-${i}`}>
          {tag}
        </li>
      ))}
    </ul>
  ) : (
    <></>
  );
}
