import "./PopupFullScreen.css";

import { useContext } from "react";
import { SidebarContext } from "../contexts/SidebarContext";
import { motion, AnimatePresence } from "framer-motion";

export default function PopupFullScreen() {
  const { isSidebarVisible } = useContext(SidebarContext);

  const durationTime = 0.25;

  const variants = {
    enter: { opacity: 0 },
    center: { opacity: 1 },
    exit: { opacity: 0 },
  };

  return (
    <AnimatePresence mode="wait" key="popup-anim" initial={false}>
      {isSidebarVisible && (
        <motion.div
          className="popup-anim-box"
          key={`popup-${isSidebarVisible ? "visible" : "hidden"}`}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{ duration: durationTime }}>
          <div className="popup-full-screen"></div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
