import "../btns.css";

export default function BuyBtn({ onClick, disable }) {
  const btnText = "Замовити";

  return (
    <button
      className={disable ? "painting-button disable" : "painting-button"}
      type="button"
      onClick={onClick}>
      {btnText}
      <span className="buy-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none">
          <rect
            x="5.5"
            y="7"
            width="14"
            height="12"
            stroke={disable ? "#797979" : "#FFFFE7"}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.5 7C8.5 4.79086 10.2909 3 12.5 3C14.7091 3 16.5 4.79086 16.5 7"
            stroke={disable ? "#797979" : "#FFFFE7"}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
    </button>
  );
}
