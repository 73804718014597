import { useState } from "react";
import icon from "./icon.svg"

import "./Img.css";

export default function Img({ className }) {
  const [loaded, setLoaded] = useState(false);

  const handleImageLoad = () => {
    setLoaded(true);
  };

  return (
    <img
      onLoad={handleImageLoad}
      className={`${className} contact-img ${loaded ? "visible" : "hidden"}`}
      src={icon}
      alt="..."
    />
  );
}
