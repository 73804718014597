import "./Order.css";

import { useContext } from "react";
import { OrderContext } from "../../contexts/OrderContext";
import { PagesContext } from "../../contexts/PagesContext";

import Img from "./img/Img";
import PaintingInfo from "./paintingInfo/PaintingInfo";
import Form from "./form/Form";
import Alert from "./Alert";
import BuyBtn from "../../btns/buy-btn/BuyBtn";

function checkDisable(userData) {
  return (
    userData.userName.length > 0 &&
    userData.userEmail.length > 0 &&
    userData.userTel.length > 0 &&
    userData.userCity.length > 0
  );
}

export default function Order() {
  const {
    checkNameOrCity,
    checkMail,
    checkTel,
    userData,
    showInvalid,
    postOrder,
  } = useContext(OrderContext);
  const { checkConnection } = useContext(PagesContext);
  const titleText = "Деталі замовлення";

  const checked =
    checkNameOrCity(userData.userName) &&
    checkMail(userData.userEmail) &&
    checkTel(userData.userTel) &&
    checkNameOrCity(userData.userCity);

  const btnDisable = !checkDisable(userData);

  return (
    <div className="order-box">
      <h1 className="order-title">{titleText}</h1>
      <div className="painting-details">
        <Img />
        <PaintingInfo />
      </div>
      <Form />
      <BuyBtn
        onClick={() => {
          !checked && showInvalid();
          checked && checkConnection(() => postOrder());
        }}
        disable={btnDisable}
      />
      <Alert />
    </div>
  );
}
