import { CarouselContext } from "../../../../contexts/CarouselContext";
import { useContext } from "react";
import CarouselLinesArrow from "./CarouselLinesArrow";

export default function CarouselLinesArrows() {
  const { translateLeft, translateRight } = useContext(CarouselContext);

  return (
    <div className="carousel-arrows">
      <CarouselLinesArrow className={"left"} onClick={() => translateLeft()} />
      <CarouselLinesArrow
        className={"right"}
        onClick={() => translateRight()}
      />
    </div>
  );
}
