import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app/App";
import { SidebarProvider } from "./contexts/SidebarContext";
import { GalleryProvider } from "./contexts/GalleryContext";
import { ArtistsProvider } from "./contexts/ArtistsContext";
import { PagesProvider } from "./contexts/PagesContext";
import { WidthProvider } from "./contexts/WidthContext";
import { BotProvider } from "./contexts/BotContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ArtistsProvider>
      <PagesProvider>
        <SidebarProvider>
          <GalleryProvider>
            <WidthProvider>
              <BotProvider>
                <App />
              </BotProvider>
            </WidthProvider>
          </GalleryProvider>
        </SidebarProvider>
      </PagesProvider>
    </ArtistsProvider>
  </React.StrictMode>
);
