import { useContext, useState } from "react";
import { GalleryContext } from "../../../contexts/GalleryContext";
import { PagesContext } from "../../../contexts/PagesContext";
import { pagesId } from "../../pagesData";
import { motion, AnimatePresence } from "framer-motion";

import Spinner from "../../../spinner/Spinner";

import "../Gallery.css";

function getMagrin(qty) {
  switch (true) {
    case qty === 3:
      return 5;

    case qty === 4:
      return 4;

    case qty === 5:
      return 3;

    case qty >= 6:
      return 2;

    default:
      return 6;
  }
}

export default function GalleryImg({ painting, qty }) {
  const [loaded, setLoaded] = useState(false);

  const handleImageLoad = () => {
    setLoaded(true);
  };

  const { setCurrentPainting, isDesktop, setVisibleQty } =
    useContext(GalleryContext);
  const { nextPage } = useContext(PagesContext);

  function handleClick(painting) {
    setCurrentPainting(painting);
    nextPage(pagesId.carousel);
    setVisibleQty();
  }

  const durationTime = 0.25;
  const variants = {
    enter: { opacity: 1 },
    center: { opacity: 1 },
    exit: { opacity: 0 },
  };

  const margin = isDesktop
    ? { margin: "6px 0" }
    : { margin: `${getMagrin(qty)}px 0` };

  const imgHeight = isDesktop ? {} : { minHeight: "20px" };

  return (
    <div
      className="painting-box"
      style={margin}
      onClick={() => handleClick(painting)}
      tabIndex={0}>
      <AnimatePresence
        mode="wait"
        key="painting-preloader-anim"
        initial={false}>
        {!loaded && (
          <motion.div
            className="painting-preloader"
            key={`painting-preloader-${!loaded ? "visible" : "hidden"}`}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{ duration: durationTime }}>
            <Spinner />
          </motion.div>
        )}
      </AnimatePresence>
      <img
        onLoad={handleImageLoad}
        style={imgHeight}
        className="painting"
        src={painting.imgSrc}
        alt={`painting-${painting.id}`}
      />
    </div>
  );
}
