import { createContext } from "react";
import axios from "axios";

export const BotContext = createContext(null);

function transformPrice(price) {
  const numbsAfterDivider = 2;
  const sec = String(price).slice(
    price.length - numbsAfterDivider,
    price.length
  );
  const fst = String(price).slice(0, price.length - numbsAfterDivider);
  return `${fst}.${sec}`;
}

function initMsg(data) {
  const isTitleVis = data.painting.title !== "";
  const isSizeVis = data.painting.size !== "";
  const msg = `Надійшло нове замовлення! 🔔

🧾 ДЕТАЛІ ЗАМОВЛЕННЯ:
<b>Номер замовлення:</b> <code>${data.orderNumb}</code>
<b>Автор:</b> <code>${data.artist}</code>${
    isTitleVis
      ? `\n<b>Назва картини:</b> "<code>${data.painting.title}</code>"`
      : ""
  }
<b>Номер картини:</b> <code>${data.painting.id}</code>${
    isSizeVis
      ? `\n<b>Розмір картини:</b> <code>${data.painting.size.replaceAll(
          " ",
          ""
        )}</code>`
      : ""
  }
<b>Вартість картини:</b> <code>${transformPrice(data.painting.price)}</code>
<b>Валюта розрахунку:</b> <code>${data.painting.currency}</code>

⬇️ ІНФОРМАЦІЯ ПРО ПОКУПЦЯ: 
<b>Ім'я:</b> <code>${data.user.name}</code>
<b>Телефон:</b> <code>${data.user.phone}</code>
<b>Пошта:</b> <code>${data.user.email}</code>
<b>Місто:</b> <code>${data.user.city}</code>`;

  return msg;
}

export function BotProvider({ children }) {
  async function sendBotMsg(data) {
    try {
      const token = "6585383529:AAFgPgLdGiaOJpOP7yEbgLMErBEizZhFf9E";
      const msg = initMsg(data);
      const userId = 480312672;

      await axios.get(
        `https://api.telegram.org/bot${token}/sendMessage?chat_id=${userId}&parse_mode=HTML&text=${encodeURIComponent(
          msg
        )}`
      );
    } catch (error) {
      console.log("Error:", error);
    }
  }

  const value = { sendBotMsg };

  return <BotContext.Provider value={value}>{children}</BotContext.Provider>;
}
