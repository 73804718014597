import "./CardInfo.css";
import { useContext } from "react";
import { GalleryContext } from "../../../../contexts/GalleryContext";

function transformPrice(price) {
  const numbsAfterDivider = 2;
  const sec = String(price).slice(
    price.length - numbsAfterDivider,
    price.length
  );
  const fst = String(price).slice(0, price.length - numbsAfterDivider);
  return `${fst}.${sec}`;
}

export default function Price() {
  const { currentPainting } = useContext(GalleryContext);
  const isVisible =
    currentPainting.price !== "" && currentPainting.currency !== "";
  const pre = "Ціна:";

  return isVisible ? (
    <p className="painting-price">
      <span className="painting-description-accent">{pre}</span>
      {`${transformPrice(currentPainting.price)} ${currentPainting.currency}`}
    </p>
  ) : (
    <></>
  );
}
