import { createContext, useState, useContext } from "react";
import { GalleryContext } from "./GalleryContext";
import { ArtistsContext } from "./ArtistsContext";
import { PagesContext } from "./PagesContext";
import { BotContext } from "./BotContext";
import { addDocument } from "../firebase/firebaseFuncs";
import { pagesId } from "../pages/pagesData";

export const OrderContext = createContext(null);

export function OrderProvider({ children }) {
  const { sendBotMsg } = useContext(BotContext);
  const { currentArtist } = useContext(ArtistsContext);
  const { currentPainting } = useContext(GalleryContext);
  const { setIsSending, nextPage, setIsBackArrowVisible } =
    useContext(PagesContext);
  const [isChecked, setIsChecked] = useState(false);
  const [numb, setNumb] = useState(0);
  const [userData, setUserData] = useState({
    userName: "",
    userEmail: "",
    userTel: "",
    userCity: "",
  });

  function inputChange(e) {
    const newData = { ...userData };
    newData[e.target.name] = e.target.value;
    setUserData(newData);
  }

  function checkNameOrCity(name) {
    const namePattern = new RegExp("^[a-zA-Zа-яА-ЯІіЇїҐґЄє'` ]{3,}$");
    return namePattern.test(name);
  }

  function checkTel(tel) {
    const telPattern = new RegExp("^[0-9+() ]{10,}$");
    return telPattern.test(tel);
  }

  function checkMail(mail) {
    const mailPattern = new RegExp(
      "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$"
    );
    return mailPattern.test(mail);
  }

  function showInvalid() {
    setIsChecked(true);
    setTimeout(() => {
      setIsChecked(false);
    }, 750);
  }

  async function postOrder() {
    const date = String(Date.now());
    const number = date.slice(date.length - 11, date.length - 3);

    try {
      const data = {
        orderNumb: number,
        artist: currentArtist.name,
        artistId: currentArtist.id,
        painting: {
          currency: currentPainting.currency,
          price: currentPainting.price,
          size: currentPainting.size,
          title: currentPainting.title,
          imgSrc: currentPainting.imgSrc,
          id: currentPainting.id,
        },
        user: {
          name: userData.userName,
          email: userData.userEmail,
          phone: userData.userTel,
          city: userData.userCity,
        },
      };

      sendBotMsg(data).catch((err) => {
        console.log("Error:", err);
      });

      addDocument("orders", data)
        .then(() => {
          setNumb(number);
          nextPage(pagesId.summary);
          setIsBackArrowVisible(false);
          setIsSending(false);
        })
        .catch((err) => {
          console.log("Error:", err);
        });
    } catch (error) {
      console.log("Error:", error);
    }
  }

  return (
    <OrderContext.Provider
      value={{
        userData,
        setUserData,
        inputChange,
        checkNameOrCity,
        checkTel,
        checkMail,
        isChecked,
        showInvalid,
        numb,
        postOrder,
        setNumb,
      }}>
      {children}
    </OrderContext.Provider>
  );
}
