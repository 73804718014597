export default function CardText({ painting, artist }) {
  const description =
    painting.year === "" ? artist.name : artist.name + ", " + painting.year;

  return (
    <div className="carousel-card-text">
      {painting.title && (
        <h3 className="carousel-card-title">{painting.title}</h3>
      )}
      <p className="carousel-card-description">{description}</p>
    </div>
  );
}
