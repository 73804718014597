import { useCallback, useRef, useContext } from "react";
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";
import { GalleryContext } from "../../contexts/GalleryContext";

import GalleryCol from "./col/GalleryCol";

import "./Gallery.css";

function getPaintingsInColNumb(qty) {
  switch (true) {
    case qty <= 9:
      return 3;

    case qty > 16 && qty <= 25:
      return 5;

    case qty > 25 && qty <= 36:
      return 6;

    case qty > 36 && qty <= 49:
      return 7;

    case qty > 49 && qty <= 64:
      return 8;

    case qty > 64 && qty <= 81:
      return 9;

    case qty > 81:
      return 10;

    default:
      return 4;
  }
}

function getGap(qty) {
  switch (true) {
    case qty === 3:
      return 10;

    case qty === 4:
      return 8;

    case qty === 5:
      return 6;

    case qty >= 6:
      return 4;

    default:
      return 12;
  }
}

export default function GalleryMobile() {
  const divRef = useRef();
  const onUpdate = useCallback(({ x, y, scale }) => {
    const { current: div } = divRef;

    if (div) {
      const value = make3dTransformValue({ x, y, scale });

      div.style.setProperty("transform", value);
    }
  }, []);

  const { currentPaintings, visibleQty } = useContext(GalleryContext);
  const paintingsInCol = getPaintingsInColNumb(currentPaintings.length);
  const paintingColumns = Math.ceil(currentPaintings.length / paintingsInCol);
  const paintingStyle = {
    gridTemplate: `1fr / repeat(${paintingColumns}, 1fr)`,
    gap: `${getGap(paintingColumns)}px`,
  };

  const newPaintingsArr = [];
  for (let i = 0; i < paintingColumns; i++) {
    newPaintingsArr.push([]);
  }

  currentPaintings.forEach((painting, i) => {
    if (i < visibleQty) newPaintingsArr[i % paintingColumns].push(painting);
  });

  const minScale = 1;

  return (
    <QuickPinchZoom
      onUpdate={onUpdate}
      maxZoom={paintingColumns + 1.5}
      minZoom={minScale}>
      <section ref={divRef} className="gallery-mobile" style={paintingStyle}>
        {newPaintingsArr.map((paintings, i) => (
          <GalleryCol
            key={`col-${i}`}
            visiblePaintings={paintings}
            index={i}
            paintingColumns={paintingColumns}
          />
        ))}
      </section>
    </QuickPinchZoom>
  );
}
