import { PagesContext } from "../../../contexts/PagesContext";
import { GalleryContext } from "../../../contexts/GalleryContext";
import { ArtistsContext } from "../../../contexts/ArtistsContext";
import { useContext } from "react";
import { pagesId } from "../../pagesData";

import "./Artist.css";

function paintingsText(paintingsNumb) {
  const regex1 = /\b(?!11\b)[0-9]*(1|21|31|41|51|61|71|81|91)\b/;
  const regex2 =
    /\b(?!12|13|14\b)[0-9]*(2|3|4|22|23|24|32|33|34|42|43|44|52|53|54|62|63|64|72|73|74|82|83|84|92|93|94)\b/;

  switch (true) {
    case regex1.test(paintingsNumb):
      return "картина";

    case regex2.test(paintingsNumb):
      return "картини";

    default:
      return "картин";
  }
}

export default function Artist({ artist }) {
  const { nextPage } = useContext(PagesContext);
  const { paintings, setCurrentPaintings } = useContext(GalleryContext);
  const { setCurrentArtist } = useContext(ArtistsContext);
  const artistPaintings =
    artist.id !== "all"
      ? paintings.filter((painting) => painting.artistId === artist.id)
      : paintings;

  const paintingsNumb = artistPaintings.length;
  const isCityVisible = Boolean(artist.city.length);
  const isCountryVisible = Boolean(artist.country.length);
  const isDividerVisible = isCityVisible && isCountryVisible;

  function setPaintingsArr() {
    setCurrentPaintings(
      artistPaintings.filter(
        (painting) =>
          painting.imgSrc !== "" && !painting.isSold && painting.isVisible
      )
    );
  }

  return (
    <div
      className="artist"
      onClick={() => {
        setCurrentArtist(artist);
        nextPage(pagesId.gallery);
        setPaintingsArr();
      }}>
      <h3 className="artist-title">{artist.name}</h3>
      <div className="artist-description">
        <p className="artist-text">{`${paintingsNumb} ${paintingsText(
          paintingsNumb
        )}`}</p>
        <p className="artist-text">
          {isCityVisible && <span>{artist.city}</span>}
          {isDividerVisible && <span>, </span>}
          {isCountryVisible && <span>{artist.country}</span>}
        </p>
      </div>
    </div>
  );
}
