import "./CardInfo.css";
import { useContext } from "react";
import { GalleryContext } from "../../../../contexts/GalleryContext";

export default function Title() {
  const { currentPainting } = useContext(GalleryContext);
  const isVisible = currentPainting.title !== "";
  const pre = "Картина";

  return isVisible ? (
    <h3 className="painting-title">{`${pre} "${currentPainting.title}"`}</h3>
  ) : (
    <></>
  );
}
