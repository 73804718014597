import { db } from "./firebaseInit.js";
import { collection, addDoc, getDocs } from "firebase/firestore";

export async function addDocument(collectionName, docData) {
  try {
    return await addDoc(collection(db, collectionName), docData);
  } catch (error) {
    console.log("Error:", error);
  }
}

export async function getDocuments(collectionName, callBack) {
  try {
    const artistsArr = [];
    const collectionRef = collection(db, collectionName);
    const querySnapshot = await getDocs(collectionRef);

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      artistsArr.push(data);
    });

    return callBack(artistsArr);
  } catch (error) {
    console.log("Error:", error);
  }
}
