import "./Sending.css";

import { useContext } from "react";
import { PagesContext } from "../contexts/PagesContext";
import { motion, AnimatePresence } from "framer-motion";

import Spinner from "../spinner/Spinner";

export default function Sending() {
  const { isSending } = useContext(PagesContext);

  const durationTime = 0.25;

  const variants = {
    enter: { opacity: 0 },
    center: { opacity: 1 },
    exit: { opacity: 0 },
  };

  return (
    <AnimatePresence mode="wait" key="popup-anim" initial={false}>
      {isSending && (
        <motion.div
          style={{ zIndex: 20 }}
          className="popup-anim-box"
          key={`popup-${isSending ? "visible" : "hidden"}`}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{ duration: durationTime }}>
          <div className="popup-full-screen sending"></div>
          <div className="sending-body">
            <Spinner />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
