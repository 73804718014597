import "./Ar.css";
import { useState, useContext, useLayoutEffect } from "react";
import { GalleryContext } from "../contexts/GalleryContext";
import { storage } from "../firebase/firebaseInit.js";
import { ref, getDownloadURL } from "firebase/storage";

export default function Ar() {
  const { currentPainting, isDesktop } = useContext(GalleryContext);
  const [isArOpen, setIsArOpen] = useState(false);
  const [modelUrl, setModelUrl] = useState(null);

  useLayoutEffect(() => {
    getDownloadURL(ref(storage, `models/${currentPainting.id}.glb`))
      .then((url) => {
        setModelUrl(url);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentPainting]);

  function hiddenBlock() {
    setIsArOpen(true);

    setTimeout(() => {
      setIsArOpen(false);
    }, 250);
  }

  return modelUrl && !isDesktop ? (
    <model-viewer
      id="viewer"
      class="model-viewer"
      src={modelUrl}
      alt="A 3D model of a painting"
      ar
      ar-placement="wall"
      ar-scale="fixed"
      touch-action="pan-y"
      shadow-intensity="1"
      camera-controls>
      <button
        slot="ar-button"
        className="painting-button ar"
        onClick={hiddenBlock}>
        Переглянути AR
      </button>
      {isArOpen && <div className="ar-button-bg"></div>}
    </model-viewer>
  ) : (
    <></>
  );
}
