import { createContext, useState, useEffect } from "react";
import { getDocuments } from "../firebase/firebaseFuncs";

export const ArtistsContext = createContext(null);

const collectionName = "artists";
const keyData = "artistsData";

export function ArtistsProvider({ children }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [artists, setArtists] = useState([]);
  const [currentArtist, setCurrentArtist] = useState(null);

  function setLoaded(artistsArr) {
    setArtists(artistsArr);
    setIsLoaded(true);
  }

  useEffect(() => {
    navigator.onLine
      ? getDocuments(collectionName, (artistsArr) => {
          setLoaded(artistsArr);
          localStorage.setItem(keyData, JSON.stringify(artistsArr));
        })
      : setLoaded(JSON.parse(localStorage.getItem(keyData)));
  }, []);

  const value = { artists, isLoaded, setCurrentArtist, currentArtist };

  return (
    <ArtistsContext.Provider value={value}>{children}</ArtistsContext.Provider>
  );
}
