import "./CardInfo.css";
import { useContext } from "react";
import { GalleryContext } from "../../../../contexts/GalleryContext";

export default function Categories() {
  const { currentPainting } = useContext(GalleryContext);
  const isVisible = currentPainting.categories.length !== 0;
  const pre = "Категорія:";
  const categories =
    currentPainting.categories.length === 1
      ? currentPainting.categories[0]
      : currentPainting.categories.join(", ");

  return isVisible ? (
    <p className="painting-description">
      <span className="painting-description-accent">{pre}</span>
      {categories}
    </p>
  ) : (
    <></>
  );
}
