import "../Summary.css";
import { useContext } from "react";
import { GalleryContext } from "../../../contexts/GalleryContext";

export default function Size() {
  const { currentPainting } = useContext(GalleryContext);
  const isVisible = currentPainting.size !== "";
  const pre = "Розмір:";

  return isVisible ? (
    <p className="painting-description">
      <span className="painting-description-accent">{pre}</span>
      {currentPainting.size}
    </p>
  ) : (
    <></>
  );
}
