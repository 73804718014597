import "./SidebarBtn.css";

import { useContext } from "react";
import { SidebarContext } from "../../contexts/SidebarContext";

export default function SidebarCloseBtn() {
  const { setIsSidebarVisible } = useContext(SidebarContext);

  return (
    <button
      type="button"
      className="sidebar-close-btn"
      onClick={() => setIsSidebarVisible(false)}>
      ЗАКРИТИ
    </button>
  );
}
