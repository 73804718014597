import "./Card.css";
import { useContext } from "react";
import { CarouselContext } from "../../../contexts/CarouselContext";
import { GalleryContext } from "../../../contexts/GalleryContext";
import { PagesContext } from "../../../contexts/PagesContext";
import { motion, AnimatePresence } from "framer-motion";
import { pagesId } from "../../../pages/pagesData.js";

import CardImg from "./card-img/CardImg";
import CardInfo from "./card-info/CardInfo";
import BuyBtn from "../../../btns/buy-btn/BuyBtn";
import Ar from "../../../ar/Ar";

export default function Card({ painting }) {
  const { isCurrent } = useContext(CarouselContext);
  const { currentPainting } = useContext(GalleryContext);
  const { nextPage } = useContext(PagesContext);
  const disable = currentPainting.price === "";

  const durationTime = 0.25;
  const variants = {
    enter: { opacity: 0, scaleX: 0.8 },
    center: { opacity: 1, scaleX: 1 },
    exit: { opacity: 0, scaleX: 0.8 },
  };

  return (
    <li
      className={`${
        painting.id === currentPainting.id && isCurrent
          ? "carousel-card current"
          : "carousel-card"
      }`}>
      <CardImg painting={painting} />
      <AnimatePresence mode="sync" key="info-anim">
        {isCurrent && (
          <motion.div
            className="info-anim-box"
            key="info"
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{ duration: durationTime }}>
            <CardInfo />
            <div className="carousel-card-btns">
              <Ar />
              <BuyBtn
                onClick={() => !disable && nextPage(pagesId.order)}
                disable={disable}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </li>
  );
}
