import "./Lines.css";

import HorizontalLine from "./horizontal-line/HorizontalLine";
import VerticalLine from "./vertical-line/VerticalLine";

export default function HeaderLines() {
  return (
    <div className="lines">
      <HorizontalLine />
      <VerticalLine />
      <HorizontalLine />
    </div>
  );
}
