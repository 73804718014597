import { useContext } from "react";
import { OrderContext } from "../../../contexts/OrderContext";

import "../Order.css";

export default function Phone() {
  const { isChecked, checkTel, userData, inputChange } =
    useContext(OrderContext);

  function checkClass(value, checkFunc) {
    if (value.length === 0) {
      return "row-input input-disable";
    } else if (!checkFunc && isChecked) {
      return "row-input input-invalid";
    } else {
      return "row-input";
    }
  }

  const labelText = "Номер телефону";
  const inputPlaceholder = "+380*********";

  return (
    <div className="order-form-row">
      <label className="row-input-label" htmlFor="userTel">
        {labelText}
      </label>
      <input
        className={checkClass(userData.userTel, checkTel(userData.userTel))}
        type="tel"
        name="userTel"
        value={userData.userTel}
        placeholder={inputPlaceholder}
        onChange={(e) => inputChange(e)}
      />
    </div>
  );
}
