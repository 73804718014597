import { useContext } from "react";
import { CarouselContext } from "../../../../contexts/CarouselContext";
import { ArtistsContext } from "../../../../contexts/ArtistsContext";

export default function CardArrow({ clickDisable, artist }) {
  const { isCurrent, setIsCurrent } = useContext(CarouselContext);
  const { setCurrentArtist } = useContext(ArtistsContext);

  return (
    <button
      type="button"
      className="carousel-card-arrow"
      style={!clickDisable ? { cursor: "default" } : { cursor: "pointer" }}
      onClick={() => {
        if (clickDisable) {
          setIsCurrent(!isCurrent);
          setCurrentArtist(artist);
        }
      }}>
      <div className="arrow-lines">
        <span className="left-line"></span>
        <span className="right-line"></span>
        <span className="center-line"></span>
      </div>
    </button>
  );
}
