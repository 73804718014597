import { useContext } from "react";
import { OrderContext } from "../../../contexts/OrderContext";

import "../Order.css";

export default function Email() {
  const { isChecked, checkMail, userData, inputChange } =
    useContext(OrderContext);

  function checkClass(value, checkFunc) {
    if (value.length === 0) {
      return "row-input input-disable";
    } else if (!checkFunc && isChecked) {
      return "row-input input-invalid";
    } else {
      return "row-input";
    }
  }

  const labelText = "Адреса електронної пошти";
  const inputPlaceholder = "example@mail.com";

  return (
    <div className="order-form-row">
      <label className="row-input-label" htmlFor="userEmail">
        {labelText}
      </label>
      <input
        className={checkClass(
          userData.userEmail,
          checkMail(userData.userEmail)
        )}
        type="email"
        name="userEmail"
        value={userData.userEmail}
        placeholder={inputPlaceholder}
        onChange={(e) => inputChange(e)}
      />
    </div>
  );
}
