import { useContext, useRef } from "react";
import { GalleryContext } from "../../contexts/GalleryContext";
import { motion, AnimatePresence } from "framer-motion";
import "./MoreBtn.css";

export default function MoreBtn() {
  const { currentPaintings, setVisibleQty, visibleQty } =
    useContext(GalleryContext);
  const btn = useRef(null);
  const btnText = "Завантажити більше картин";

  const durationTime = 0.125;
  const variants = {
    enter: { opacity: 0 },
    center: { opacity: 1 },
    exit: { opacity: 0 },
  };

  const removeFocus = (btn) =>
    currentPaintings.length > visibleQty && btn.current.blur();

  return (
    <AnimatePresence mode="wait" key="btn-gallery-anim" initial={false}>
      {currentPaintings.length > visibleQty && (
        <motion.div
          className="btn-gallery-container"
          key={`btn-gallery-${
            currentPaintings.length > visibleQty ? "visible" : "hidden"
          }`}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{ duration: durationTime }}>
          <div className="btn-gallery-bg">
            <button
              ref={btn}
              type="button"
              className="painting-button gallery-btn"
              onClick={() => {
                setVisibleQty(currentPaintings.length);
                removeFocus(btn);
              }}>
              {btnText}
            </button>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
