import { createContext, useContext, useState } from "react";
import { WidthContext } from "./WidthContext";
import { GalleryContext } from "./GalleryContext";

export const CarouselContext = createContext(null);

export function CarouselProvider({ children }) {
  const [isCurrent, setIsCurrent] = useState(false);
  const [isTransform, setIsTransform] = useState(false);
  const { windowWidth } = useContext(WidthContext);
  const { currentPainting, setCurrentPainting, currentPaintings } =
    useContext(GalleryContext);
  const marginPaintingWidth = 16;

  let carouselWidth = 0;
  let paintingWidth = 0;
  let carouselIndex = 0;
  let carouselPos = 0;
  let qtyPaintings = 0;
  let marginWidth = 0;

  if (currentPaintings && currentPainting) {
    const isSmall = Math.round(windowWidth * 0.81067) <= 520;
    paintingWidth = isSmall ? Math.round(windowWidth * 0.81067) : 520;
    marginWidth = (windowWidth - paintingWidth) / 2;
    qtyPaintings = currentPaintings.length;
    carouselWidth =
      qtyPaintings * paintingWidth + (qtyPaintings - 1) * marginPaintingWidth;

    carouselIndex = currentPaintings.findIndex(
      (painting) => painting.id === currentPainting.id
    );
    carouselPos = carouselIndex * (paintingWidth + marginPaintingWidth);
  }

  function translateLeft() {
    if (carouselIndex !== 0) {
      setCurrentPainting(currentPaintings[carouselIndex - 1]);
    }
  }

  function translateRight() {
    if (carouselIndex < qtyPaintings - 1) {
      setCurrentPainting(currentPaintings[carouselIndex + 1]);
    }
  }

  const value = {
    carouselWidth,
    carouselPos,
    marginWidth,
    translateLeft,
    translateRight,
    isCurrent,
    setIsCurrent,
    isTransform,
    setIsTransform,
  };

  return (
    <CarouselContext.Provider value={value}>
      {children}
    </CarouselContext.Provider>
  );
}
