import { useContext } from "react";
import { GalleryContext } from "../../contexts/GalleryContext";

import GalleryDesktop from "./GalleryDesktop";
import GalleryMobile from "./GalleryMobile";

import "./Gallery.css";

export default function Gallery() {
  const { isDesktop, currentPaintings } = useContext(GalleryContext);
  const isEmpty = currentPaintings.find((painting) => painting.imgHref !== "");
  const emptyText = "Даний художник не має картин для перегляду.";

  const desktopStyle = { minHeight: "100%", height: "auto" };
  const mobileStyle = { minHeight: "auto", height: "100%" };

  return (
    <section className="gallery" style={isDesktop ? desktopStyle : mobileStyle}>
      {!isEmpty ? (
        <div className="empty">
          <h2 className="empty-text artist-title">{emptyText}</h2>
        </div>
      ) : isDesktop ? (
        <GalleryDesktop />
      ) : (
        <GalleryMobile />
      )}
    </section>
  );
}
