import Title from "./Title";
import Size from "./Size";
import SubTitle from "./SubTitle";

import "../Summary.css";

export default function PaintingInfo() {
  return (
    <div className="painting-info">
      <Title />
      <SubTitle />
      <Size />
    </div>
  );
}
