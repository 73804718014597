import "../Gallery.css";
import GalleryImg from "../img/GalleryImg";

export default function GalleryCol({ visiblePaintings, index, paintingColumns }) {
  const marginStap = 3;
  const style = { marginTop: `${(index % marginStap) * 12}px` };

  return (
    <div className="gallery-col" style={style}>
      {visiblePaintings.map((painting, i) => (
        <GalleryImg
          key={`img-${i}`}
          painting={painting}
          qty={paintingColumns}
        />
      ))}
    </div>
  );
}
