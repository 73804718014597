import { createContext, useState } from "react";
import { pagesId } from "../pages/pagesData.js";

export const PagesContext = createContext(null);

export function PagesProvider({ children }) {
  const [isSending, setIsSending] = useState(false);
  const [isOffline, setIsOffline] = useState(false);
  const [currentPage, setCurrentPage] = useState(pagesId.artists);
  const [memory, setMemory] = useState([pagesId.artists]);
  const [isBackArrowVisible, setIsBackArrowVisible] = useState(false);

  const nextPage = (id) => {
    if (memory[0] !== id) {
      setCurrentPage(id);
      memory.length > 13
        ? setMemory([id, ...memory.filter((page, i) => i <= 13)])
        : setMemory([id, ...memory]);
      setIsBackArrowVisible(true);
    }
  };

  const prevPage = () => {
    if (memory[1] !== pagesId.summary) {
      setCurrentPage(memory[1]);
      setMemory(memory.filter((page, i) => i !== 0));
      if (memory[1] === pagesId.artists) setIsBackArrowVisible(false);
    } else {
      setCurrentPage(memory[1]);
      setMemory(memory.filter((page, i) => i !== 0));
      setIsBackArrowVisible(false);
    }
  };

  const homePage = (id) => {
    if (memory[0] !== id) {
      setCurrentPage(id);
      memory.length > 13
        ? setMemory([id, ...memory.filter((page, i) => i <= 13)])
        : setMemory([id, ...memory]);
    }
  };

  function checkConnection(callBack) {
    if (navigator.onLine) {
      setIsSending(true);
      callBack();
    } else {
      setIsOffline(true);
      setInterval(() => {
        setIsOffline(false);
      }, 5000);
    }
  }

  const value = {
    currentPage,
    setCurrentPage,
    nextPage,
    prevPage,
    isBackArrowVisible,
    checkConnection,
    isOffline,
    isSending,
    setIsSending,
    setIsBackArrowVisible,
    homePage,
  };

  return (
    <PagesContext.Provider value={value}>{children}</PagesContext.Provider>
  );
}
