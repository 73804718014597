import "./ContactShort.css";

import teleg from "./telegram.svg";
import phone from "./phone.svg";
import Img from "./img/Img";

export default function ContactShort() {
  return (
    <div className="contact-anim-box">
      <h2 className="contacts-title">Контакти</h2>
      <p className="contacts-subtitle">
        Якщо у Вас виникли запитання або Ви хочете замовити картину, зв'яжіться
        з нами в Telegram групі або по телефону:
      </p>
      <div className="contacts-network-links">
        <a
          className="contacts-network-link"
          href="https://t.me/+f7zh9aXWFzk2MWEy"
          target="_blank"
          rel="noopener noreferrer nofollow">
          <Img src={teleg} className={"contacts-network-link-img"} />
        </a>
        <a className="contacts-network-link" href="tel:+380730232313">
          <Img src={phone} className={"contacts-network-link-img"} />
        </a>
      </div>
    </div>
  );
}
