import { useState } from "react";

import "./Img.css";

export default function Img({ src, className }) {
  const [loaded, setLoaded] = useState(false);

  const handleImageLoad = () => {
    setLoaded(true);
  };

  return (
    <img
      onLoad={handleImageLoad}
      className={`${className} contact-img ${loaded ? "visible" : "hidden"}`}
      src={src}
      alt="..."
    />
  );
}
