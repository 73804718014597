import "./Policy.css";

export default function Policy() {
  const sections = [
    {
      title: "Загальні положення",
      items: [
        "Ця Політика конфіденційності регулює збір, зберігання та обробку особистих даних користувачів сайту з віртуальною AR галереєю.",
        "Використовуючи цей сайт, ви погоджуєтесь з умовами цієї Політики конфіденційності.",
      ],
    },
    {
      title: "Збір інформації",
      items: [
        "Ми можемо збирати особисті дані, такі як ім'я, електронна пошта, та інша інформація, яку ви нам надаєте.",
        "Ми можемо автоматично отримувати технічну інформацію, таку як IP-адреси, тип браузера, мова браузера та час доступу до сайту.",
        "Ми можемо використовувати куки та інші технології для збору інформації та покращення функціональності сайту.",
      ],
    },
    {
      title: "Використання інформації",
      items: [
        "Зібрана інформація використовується для забезпечення доступу до віртуальної AR галереї, покращення сервісу та надання персоналізованого досвіду.",
        "Ми можемо використовувати ваші контактні дані для надсилання повідомлень про нові функції, акції та оновлення.",
      ],
    },
    {
      title: "Захист інформації",
      items: [
        "Ми зобов'язуємося забезпечити безпеку вашої інформації та використовувати заходи для захисту від несанкціонованого доступу.",
      ],
    },
    {
      title: "Розкриття інформації",
      items: [
        "Ми можемо розкривати інформацію третім сторонам лише з вашого згоди або відповідно до законодавства.",
      ],
    },
    {
      title: "Посилання на інші сайти",
      items: [
        "Наш сайт може містити посилання на інші сайти. Ми не несемо відповідальності за їхні політики конфіденційності.",
      ],
    },
    {
      title: "Зміни в політиці конфіденційності",
      items: [
        "Ми можемо час від часу оновлювати цю Політику конфіденційності. Зміни набудуть чинності в момент публікації на сайті.",
      ],
    },
    {
      title: "Зв'язок з нами",
      items: [
        "Якщо у вас є питання або зауваження щодо цієї Політики конфіденційності, звертайтеся за телефоном +380730232313.",
      ],
    },
  ];

  return (
    <div className="policy-anim-box">
      <h2 className="policy-title">Політика конфіденційності</h2>
      <ul>
        {sections.map((section, i) => (
          <li className="policy-subtitle" key={`title-${i}`}>
            <h3 className="policy-subtitle" key={`title-${i}`}>
              {`${i + 1}. ${section.title}`}
            </h3>
            {section.items.map((item, j) => (
              <p className="policy-text" key={`text-${j}`}>{`${i + 1}.${
                j + 1
              } ${item}`}</p>
            ))}
          </li>
        ))}
      </ul>
    </div>
  );
}
