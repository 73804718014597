import "./Sidebar.css";

import { useContext } from "react";
import { SidebarContext } from "../contexts/SidebarContext";
import { PagesContext } from "../contexts/PagesContext";
import { motion, AnimatePresence } from "framer-motion";
import { pagesId } from "../pages/pagesData";

import Logo from "../logo/Logo";
import HorizontalLine from "../lines/horizontal-line/HorizontalLine";
import MenuCloseBtn from "../btns/sidebar-btn/SidebarCloseBtn";

export default function Sidebar() {
  const { isSidebarVisible, setIsSidebarVisible } = useContext(SidebarContext);
  const { nextPage } = useContext(PagesContext);

  const durationTime = 0.25;
  let variants;

  if (window.innerWidth >= 500) {
    variants = {
      enter: { transform: "translateX(250px)" },
      center: { transform: "translateX(0)" },
      exit: { transform: "translateX(250px)" },
    };
  } else {
    variants = {
      enter: { transform: "translateX(50.01%)" },
      center: { transform: "translateX(0)" },
      exit: { transform: "translateX(50.01%)" },
    };
  }

  return (
    <AnimatePresence mode="wait" key="sidebar-anim">
      {isSidebarVisible && (
        <motion.div
          className="sidebar-anim-box"
          key={`sidebar-${isSidebarVisible ? "visible" : "hidden"}`}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{ duration: durationTime }}>
          <aside className="sidebar">
            <div className="close-btn-box">
              <MenuCloseBtn />
            </div>
            <div className="top-text-box">
              <button
                className="sidebar-text button top"
                type="button"
                onClick={() => {
                  nextPage(pagesId.policy);
                  setIsSidebarVisible(false);
                }}>
                УМОВИ
              </button>
            </div>
            <HorizontalLine />
            <Logo />
            <HorizontalLine />
            <div className="bot-text-box">
              <button
                className="sidebar-text button bot"
                type="button"
                onClick={() => {
                  nextPage(pagesId.contact);
                  setIsSidebarVisible(false);
                }}>
                КОНТАКТИ
              </button>
            </div>
          </aside>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
