import "../Summary.css";
import { useContext } from "react";
import { GalleryContext } from "../../../contexts/GalleryContext";
import { ArtistsContext } from "../../../contexts/ArtistsContext";

export default function SubTitle() {
  const { currentPainting } = useContext(GalleryContext);
  const { currentArtist } = useContext(ArtistsContext);

  const isYearVisible = currentPainting.year !== "";
  const isArtistVisible = currentArtist.name !== "";
  const isDividerVisible = isYearVisible && isArtistVisible;
  const isVisible = isYearVisible || isArtistVisible;

  return (
    isVisible && (
      <p className="painting-author">
        {isArtistVisible && <span>{currentArtist.name}</span>}
        {isDividerVisible && <span>, </span>}
        {isYearVisible && <span>{currentPainting.year}</span>}
      </p>
    )
  );
}
