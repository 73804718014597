import "./SidebarBtn.css";

import { useContext } from "react";
import { SidebarContext } from "../../contexts/SidebarContext";

export default function SidebarOpenBtn() {
  const { setIsSidebarVisible } = useContext(SidebarContext);

  return (
    <button
      type="button"
      className="sidebar-open-btn"
      onClick={() => setIsSidebarVisible(true)}>
      МЕНЮ
    </button>
  );
}
