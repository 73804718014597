import { useContext } from "react";
import { ArtistsContext } from "../../contexts/ArtistsContext";
import { WidthContext } from "../../contexts/WidthContext";

import Artist from "./artist/Artist";
import "./Artists.css";

const allPaintings = { name: "Всі картини", id: "all", city: "", country: "" };

export default function Artists() {
  const { artists, isLoaded } = useContext(ArtistsContext);
  const { windowWidth } = useContext(WidthContext);

  const maxArtictBoxWidth = 352;
  const artistsColumns = Math.floor(windowWidth / maxArtictBoxWidth);
  const artistsStyle = {
    gridTemplate: `1fr / repeat(${artistsColumns}, 1fr)`,
  };

  const artistsArr = artists.filter((artist) => artist.isVisible === true);

  return (
    <ul className="artists" style={artistsStyle}>
      {isLoaded &&
        artistsArr
          .map((artist, i) => <Artist artist={artist} key={i} />)
          .concat(<Artist artist={allPaintings} key="n" />)}
    </ul>
  );
}
