import "./Logo.css";

export default function Logo() {
  return (
    <a href="/" className="logo">
      Virtual Art&nbsp;
      <span className="logo-decorize">G</span>allery
    </a>
  );
}
