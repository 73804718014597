import { createContext, useState, useEffect, useReducer } from "react";
import { getDocuments } from "../firebase/firebaseFuncs";

export const GalleryContext = createContext(null);

const collectionName = "paintings";
const keyData = "paintingsData";

export function GalleryProvider({ children }) {
  const qtyStep = 10;
  const [paintings, setPaintings] = useState([]);
  const [currentPaintings, setCurrentPaintings] = useState([]);
  const [visibleQty, setVisibleQty] = useReducer((visibleQty, paintingsQty) => {
    if (paintingsQty) {
      const qty = visibleQty + qtyStep;
      return qty <= paintingsQty ? qty : paintingsQty;
    }

    return qtyStep;
  }, qtyStep);
  const [currentPainting, setCurrentPainting] = useState(null);
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    let isTouchDevice = false;
    let isSmWidthDevice = false;

    if (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    ) {
      isTouchDevice = true;
    }

    if (window.matchMedia("only screen and (max-width: 520px)").matches) {
      isSmWidthDevice = true;
    }

    setIsDesktop(!(isTouchDevice && isSmWidthDevice));
  }, []);

  useEffect(() => {
    navigator.onLine
      ? getDocuments(collectionName, (paintingsArr) => {
          setPaintings(paintingsArr);
          localStorage.setItem(keyData, JSON.stringify(paintingsArr));
        })
      : setPaintings(JSON.parse(localStorage.getItem(keyData)));
  }, []);

  return (
    <GalleryContext.Provider
      value={{
        currentPainting,
        setCurrentPainting,
        isDesktop,
        setPaintings,
        paintings,
        visibleQty,
        setVisibleQty,
        currentPaintings,
        setCurrentPaintings,
      }}>
      {children}
    </GalleryContext.Provider>
  );
}
