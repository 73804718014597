import "./CardInfo.css";
import { useContext } from "react";
import { GalleryContext } from "../../../../contexts/GalleryContext";
import { ArtistsContext } from "../../../../contexts/ArtistsContext";

export default function SubTitle() {
  const { currentPainting } = useContext(GalleryContext);
  const { currentArtist } = useContext(ArtistsContext);

  const isYearVisible = currentPainting.year !== "";
  const isCityVisible = currentPainting.city !== "";
  const isArtistVisible = currentArtist.name !== "";
  const isDividerVisible = isYearVisible && isCityVisible;
  const isHidden = !isYearVisible && !isCityVisible && !isArtistVisible;

  return isHidden ? (
    <></>
  ) : (
    <div className="painting-subtitle-info">
      {isArtistVisible && (
        <p className="painting-author">{currentArtist.name}</p>
      )}
      <p className="painting-place">
        {isCityVisible && <span>{currentPainting.city}</span>}
        {isDividerVisible && <span>, </span>}
        {isYearVisible && <span>{currentPainting.year}</span>}
      </p>
    </div>
  );
}
