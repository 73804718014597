import "../Summary.css";

import { useContext } from "react";
import { OrderContext } from "../../../contexts/OrderContext";

import Price from "./Price";
import InfoRow from "./InfoRow";

export default function CustomerInfo() {
  const { userData } = useContext(OrderContext);

  const infoText =
    "Щодо отримання замолення та оплати з Вами зв'яжеться менеджер Virtual Art Gallery.";
  const rows = [
    { label: "Ім'я:", value: userData.userName },
    { label: "Пошта:", value: userData.userEmail },
    { label: "Телефон:", value: userData.userTel },
  ];

  return (
    <div className="customer-info">
      {rows.map((row, i) => (
        <InfoRow label={row.label} value={row.value} key={`row-${i}`} />
      ))}
      <Price />
      <p className="customer-info-text">{infoText} </p>
    </div>
  );
}
