import "./Header.css";
import { useContext } from "react";
import { SidebarContext } from "../contexts/SidebarContext";
import { PagesContext } from "../contexts/PagesContext";
import { CarouselContext } from "../contexts/CarouselContext";
import { GalleryContext } from "../contexts/GalleryContext";
import { motion, AnimatePresence } from "framer-motion";

import HeaderLines from "../lines/HeaderLines";
import Logo from "../logo/Logo";
import SidebarOpenBtn from "../btns/sidebar-btn/SidebarOpenBtn";

export default function Header() {
  const { isSidebarVisible } = useContext(SidebarContext);
  const { setVisibleQty } = useContext(GalleryContext);
  const { setIsCurrent } = useContext(CarouselContext);
  const { isBackArrowVisible, prevPage } = useContext(PagesContext);

  const durationTime = 0.125;
  const variants = {
    enter: { opacity: 0 },
    center: { opacity: 1 },
    exit: { opacity: 0 },
  };

  function handleClick() {
    prevPage();
    setTimeout(() => {
      setIsCurrent(false);
      setVisibleQty();
    }, 125);
  }

  return (
    <header className="header">
      <HeaderLines />
      <nav className="header-nav">
        <Logo />
        {!isSidebarVisible && <SidebarOpenBtn />}
      </nav>
      <AnimatePresence mode="sync" key="btn-anim">
        {isBackArrowVisible && (
          <motion.div
            className="carousel-btn-anim-box"
            key="btn"
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{ duration: durationTime }}>
            <button
              style={{ marginTop: "8px" }}
              type="button"
              className="carousel-back-arrow"
              onClick={handleClick}>
              <div className="carousel-arrow-lines">
                <span className="carousel-top-line"></span>
                <span className="carousel-bot-line"></span>
                <span className="carousel-center-line"></span>
              </div>
            </button>
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  );
}
