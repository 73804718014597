import { useContext, useRef, useState } from "react";
import { CarouselContext } from "../../contexts/CarouselContext";
import { GalleryContext } from "../../contexts/GalleryContext";
import { useSwipeable } from "react-swipeable";
import { motion, AnimatePresence } from "framer-motion";

import Card from "./card/Card";
import CarouselLines from "./lines/CarouselLines";
import "./Carousel.css";

export default function Carousel() {
  const [isSwipedUp, setIsSwipedUp] = useState(false);
  const {
    carouselWidth,
    carouselPos,
    translateLeft,
    translateRight,
    isCurrent,
    setIsCurrent,
    marginWidth,
  } = useContext(CarouselContext);
  const { currentPaintings } = useContext(GalleryContext);

  const myRef = useRef();

  const refPassthrough = (el) => {
    handlers.ref(el);
    myRef.current = el;
  };

  const handlers = useSwipeable({
    onSwipedUp: () => {
      !isCurrent && setIsCurrent(true);
      isCurrent && !isSwipedUp && setIsSwipedUp(true);
    },
    onSwipedDown: () => {
      isCurrent && isSwipedUp && setIsSwipedUp(false);
      isCurrent && !isSwipedUp && setIsCurrent(false);
    },
    onSwipedLeft: () => {
      !isCurrent && translateRight();
    },
    onSwipedRight: () => {
      !isCurrent && translateLeft();
    },
  });

  const style = {
    width: `${carouselWidth}px`,
    transform: `translateX(-${carouselPos}px)`,
    margin: `8px ${marginWidth}px`,
  };

  const durationTime = 0.25;

  const variantsLines = {
    enter: { opacity: 0 },
    center: { opacity: 1 },
    exit: { opacity: 0 },
  };

  return (
    <div className={`${isCurrent ? "carousel hidden" : "carousel"}`}>
      <ul ref={refPassthrough} className="carousel-cards" style={style}>
        {currentPaintings.map((painting, i) => (
          <Card key={`painting-${i}`} painting={painting} />
        ))}
      </ul>
      <AnimatePresence mode="wait" key="carousel-anim" initial={false}>
        {!isCurrent && (
          <motion.div
            className="carousel-card-anim-box"
            key={`carousel-card-box-${isCurrent ? "h" : "v"}`}
            variants={variantsLines}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{ duration: durationTime }}>
            <CarouselLines />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
