import { createContext, useState, useEffect } from "react";

export const WidthContext = createContext(null);

export function WidthProvider({ children }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <WidthContext.Provider value={{ windowWidth }}>
      {children}
    </WidthContext.Provider>
  );
}
