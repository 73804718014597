import "../Summary.css";

export default function InfoRow({ label, value }) {
  return (
    <p className="customer-description">
      <span className="customer-description-accent">{label}</span>
      {value}
    </p>
  );
}
