import React, { useState, useEffect, useContext } from "react";
import { GalleryContext } from "../../../contexts/GalleryContext";
import { motion, AnimatePresence } from "framer-motion";

import Spinner from "../../../spinner/Spinner";

export default function Img() {
  const { currentPainting } = useContext(GalleryContext);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const image = new Image();
    image.src = currentPainting.imgSrc;
    image.onload = () => {
      setLoaded(true);
    };
  }, [currentPainting]);

  const durationTime = 0.25;
  const variants = {
    enter: { opacity: 1 },
    center: { opacity: 1 },
    exit: { opacity: 0 },
  };

  return (
    <div className="painting-container">
      <AnimatePresence
        mode="wait"
        key="painting-preloader-anim"
        initial={false}>
        {!loaded && (
          <motion.div
            className="painting-preloader"
            key={`painting-preloader-${!loaded ? "visible" : "hidden"}`}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{ duration: durationTime }}>
            <Spinner />
          </motion.div>
        )}
      </AnimatePresence>
      <div
        className="carousel-card-bg"
        style={{
          background: `url(${currentPainting.imgSrc}) center center / cover no-repeat`,
        }}></div>
    </div>
  );
}
