import { useContext } from "react";
import { OrderContext } from "../../../contexts/OrderContext";

import "../Order.css";

export default function City() {
  const { isChecked, checkNameOrCity, userData, inputChange } =
    useContext(OrderContext);

  function checkClass(value, checkFunc) {
    if (value.length === 0) {
      return "row-input input-disable";
    } else if (!checkFunc && isChecked) {
      return "row-input input-invalid";
    } else {
      return "row-input";
    }
  }

  const labelText = "Місто";
  const inputPlaceholder = "Київ";

  return (
    <div className="order-form-row">
      <label className="row-input-label" htmlFor="userCity">
        {labelText}
      </label>
      <input
        className={checkClass(
          userData.userCity,
          checkNameOrCity(userData.userCity)
        )}
        type="text"
        name="userCity"
        value={userData.userCity}
        placeholder={inputPlaceholder}
        onChange={(e) => inputChange(e)}
      />
    </div>
  );
}
