export default function CarouselLinesArrow({ onClick, className }) {
  return (
    <div className={`carousel-btn-anim-box ${className}`}>
      <button type="button" className="carousel-back-arrow" onClick={onClick}>
        <div className="carousel-arrow-lines">
          <span className="carousel-top-line"></span>
          <span className="carousel-bot-line"></span>
          <span className="carousel-center-line"></span>
        </div>
      </button>
    </div>
  );
}
