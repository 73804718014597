import "./Summary.css";

import { useContext } from "react";
import { OrderContext } from "../../contexts/OrderContext";
import { PagesContext } from "../../contexts/PagesContext";
import { ArtistsContext } from "../../contexts/ArtistsContext";
import { CarouselContext } from "../../contexts/CarouselContext";
import { GalleryContext } from "../../contexts/GalleryContext";
import { pagesId } from "../pagesData";

import HorizontalLine from "../../lines/horizontal-line/HorizontalLine";
import Img from "./img/Img";
import PaintingInfo from "./paintingInfo/PaintingInfo";
import CustomerInfo from "./customerInfo/CustomerInfo";

export default function Summary() {
  const { numb, setNumb, setUserData } = useContext(OrderContext);
  const { homePage } = useContext(PagesContext);
  const { setCurrentArtist } = useContext(ArtistsContext);
  const { setIsCurrent, setIsTransform } = useContext(CarouselContext);
  const { setCurrentPaintings, setCurrentPainting } =
    useContext(GalleryContext);

  const titleText = "Деталі замовлення";
  const subtitleText = "Успішно оформлено!";
  const preOrderNumbText = "Замовлення №";
  const questionTitleText = "Якщо залишилися будь-які запитання:";
  const tel = "+380730232313";
  const telTitle = "Телефон:";
  const btnText = "На головну";

  function goHome() {
    homePage(pagesId.artists);
    setTimeout(() => {
      setCurrentArtist(null);
      setIsCurrent(false);
      setIsTransform(false);
      setCurrentPaintings([]);
      setCurrentPainting(null);
      setNumb(0);
      setUserData({
        userName: "",
        userEmail: "",
        userTel: "",
        userCity: "",
      });
    }, 500);
  }

  return (
    <div className="details-anim-box">
      <h2 className="details-title">{titleText}</h2>
      <Img className={"details-icon"} />
      <h3 className="details-subtitle">{subtitleText}</h3>
      <p className="details-order-number">{`${preOrderNumbText} ${numb}`}</p>
      <PaintingInfo />
      <HorizontalLine />
      <CustomerInfo />
      <HorizontalLine />
      <p className="question-info-text">{questionTitleText}</p>
      <a href={`tel:${tel}`} className="question-link">
        <span className="question-link-accent">{telTitle}</span>
        {tel}
      </a>
      <button className="painting-button" type="button" onClick={goHome}>
        {btnText}
      </button>
    </div>
  );
}
