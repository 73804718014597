import { motion, AnimatePresence } from "framer-motion";
import { useContext } from "react";
import { PagesContext } from "../contexts/PagesContext";
import { pagesId } from "../pages/pagesData.js";
import { CarouselProvider } from "../contexts/CarouselContext.js";
import { OrderProvider } from "../contexts/OrderContext.js";

import "./reset.css";
import "./App.css";

import Artists from "../pages/artists/Artists.js";
import Header from "../header/Header";
import Sidebar from "../sidebar/Sidebar";
import PopupFullScreen from "../popup/PopupFullScreen";
import Policy from "../pages/policy/Policy.js";
import ContactShort from "../pages/contact-short/ContactShort.js";
import Gallery from "../pages/gallery/Gallery.js";
import Carousel from "../pages/carousel/Carousel.js";
import Order from "../pages/order/Order.js";
import Offline from "../offline/Offline.js";
import Sending from "../sending/Sending.js";
import Summary from "../pages/summary/Summary.js";
import ArtistTitle from "./artistTitle/ArtistTitle.js";
import MoreBtn from "../btns/moreBtn/MoreBtn.js";

function setComponent(pageId) {
  switch (pageId) {
    case pagesId.artists:
      return <Artists />;

    case pagesId.policy:
      return <Policy />;

    case pagesId.contact:
      return <ContactShort />;

    case pagesId.gallery:
      return <Gallery />;

    case pagesId.carousel:
      return <Carousel />;

    case pagesId.order:
      return <Order />;

    case pagesId.summary:
      return <Summary />;

    default:
      return <Artists />;
  }
}

export default function App() {
  const { currentPage } = useContext(PagesContext);

  const durationTime = 0.125;
  const variantsApp = {
    enter: { opacity: 0.5 },
    center: { opacity: 1 },
    exit: { opacity: 0.5 },
  };

  return (
    <CarouselProvider>
      <OrderProvider>
        <AnimatePresence mode="wait" key="app-anim">
          <motion.div
            className="app-wrap"
            key="app"
            variants={variantsApp}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{ duration: durationTime }}>
            <Header />
            <Sidebar />
            <PopupFullScreen />
            <Offline />
            <Sending />
            <AnimatePresence mode="wait" key="pages-anim">
              <motion.div
                className="pages-wrap"
                key={currentPage}
                variants={variantsApp}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{ duration: durationTime }}>
                {currentPage === pagesId.gallery && (
                  <>
                    <ArtistTitle />
                    <MoreBtn />
                  </>
                )}
                {setComponent(currentPage)}
              </motion.div>
            </AnimatePresence>
          </motion.div>
        </AnimatePresence>
      </OrderProvider>
    </CarouselProvider>
  );
}
