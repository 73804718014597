import "../../../lines/Lines.css";
import "./CarouselLines.css";

import HorizontalLine from "../../../lines/horizontal-line/HorizontalLine";
import VerticalLine from "../../../lines/vertical-line/VerticalLine";
import CarouselLinesArrows from "./arrows/CarouselLinesArrows";

export default function CarouselLines() { 
  return (
    <div className="carousel-bot">
      <CarouselLinesArrows />
      <div className="lines">
        <HorizontalLine />
        <VerticalLine />
        <HorizontalLine />
      </div>
    </div>
  );
}
