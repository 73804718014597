import "../Order.css";

import Name from "./Name";
import Email from "./Email";
import Phone from "./Phone";
import City from "./City";

export default function Form() {
  const titleText = "Будь ласка, заповніть особисту інформацію";

  return (
    <div className="order-form">
      <h4 className="order-form-title">{titleText}</h4>
      <Name />
      <Email />
      <Phone />
      <City />
    </div>
  );
}
